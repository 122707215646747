///* eslint-disable @typescript-eslint/no-unused-vars */

//import React from 'react';
//import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';
//import HomePage from './Home';
//import FaqPage from './Faq';
//import HeaderHome from './Header';
//import Headerx from './Headerx';
//import styled, { keyframes } from "styled-components";
//import GetQuotePage from './GetQuote';
//import AboutUsPage from './AboutUs';
//import CollectionPage from './Collections';

//const App = () => {

//    const HeaderLoad = () => {
//        const location = useLocation();
//        return location.pathname === '/' ? <HeaderHome /> : <Headerx />;
//    }

//    return (
//        <Router>
//            <HeaderLoad />
//            <div className="app-container">
//                <Routes>
//                    <Route path="/" element={<HomePage />} />
//                    <Route path="/faq" element={<FaqPage />} />
//                    <Route path="/getquote" element={<GetQuotePage route="getquote"/>} />
//                    <Route path="/contactus" element={<GetQuotePage route="contactus" />} />
//                    <Route path="/aboutus" element={<AboutUsPage />} />
//                    <Route path="/collection" element={<CollectionPage />} />
//                </Routes>
//            </div>

//            <Footer>
//            <FooterContainer>
//                <LinksContainer>
//                    <DDLink href="/aboutus">ABOUT US</DDLink>
//                    <DDLink href="/contactus">CONTACT US</DDLink>
//                    <DDLink href="https://www.facebook.com/groups/divnordiamondsgroup" target="blank">
//                        <Icon className="fab fa-facebook-f"></Icon>
//                    </DDLink>
//                    <DDLink href="https://www.instagram.com/divnordiamonds/" target="blank">
//                        <Icon className="fab fa-instagram"></Icon>
//                    </DDLink>
//                </LinksContainer>
//                <Copyright>&copy; Divnor Diamonds. All rights reserved</Copyright>
//                </FooterContainer>
//            </Footer>
//        </Router>
//    );
//};

//const AppContainer = styled.div`
//    //padding-bottom: 20rem; /* adjust this value as needed */
//`;

//const Footer = styled.footer`
//background-color: #333; /* Example background color for the footer */
//  color: white; /* Example text color for the footer */
//  text-align: center;
//  padding: 20px;
//  position: sticky;
//  bottom: 0;
//`;

//const FooterContainer = styled.div`
// height: 120px;
//  background: #B3C6CB;
//  display: flex;
//  flex-direction: column;
//  justify-content: center;
//  align-items: center;
//  padding: 1rem 10vw;
//  font-family: 'Garamond', serif;
//  letter-spacing:2px;
//  font-size: 16px;

//`;

//const StyledFooter = styled(FooterContainer)`
//    //position: fixed;
//    //bottom: 0;
//    width: 100%;
//`;

//const LinksContainer = styled.div`
//  display: flex;
//  justify-content: space-between;
//  width: 100%;
//`;

//const DDLink = styled.a`
//  color: #202D30;
//  text-decoration: none;
//`;

//const Icon = styled.i`
//  font-size: 1.5rem;
//`;

//const Copyright = styled.p`
//  color: #202D30;
//  text-align: center;
//`;

//export default App;



/* eslint-disable @typescript-eslint/no-unused-vars */

import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useLocation } from 'react-router-dom';
import HomePage from './Home';
import FaqPage from './Faq';
import HeaderHome from './Header';
import Headerx from './Headerx';
import styled, { keyframes } from "styled-components";
import GetQuotePage from './GetQuote';
import AboutUsPage from './AboutUs';
import CollectionPage from './Collections';

const App = () => {

    const HeaderLoad = () => {
        const location = useLocation();
        return location.pathname === '/' ? <HeaderHome /> : <Headerx />;
    }

    return (
        <Router>
            <AppContainer>
            <HeaderLoad />
                <MainContent>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/faq" element={<FaqPage />} />
                        <Route path="/getquote" element={<GetQuotePage route="getquote" />} />
                        <Route path="/contactus" element={<GetQuotePage route="contactus" />} />
                        <Route path="/aboutus" element={<AboutUsPage />} />
                        <Route path="/collection" element={<CollectionPage />} />
                    </Routes>
                </MainContent>

                <FooterContainer>
                    <LinksContainer>
                        <DDLink href="/aboutus">ABOUT US</DDLink>
                        <DDLink href="/contactus">CONTACT US</DDLink>
                        <DDLink href="https://www.facebook.com/groups/divnordiamondsgroup" target="blank">
                            <Icon className="fab fa-facebook-f"></Icon>
                        </DDLink>
                        <DDLink href="https://www.instagram.com/divnordiamonds/" target="blank">
                            <Icon className="fab fa-instagram"></Icon>
                        </DDLink>
                    </LinksContainer>
                    <Copyright>&copy; Divnor Diamonds. All rights reserved</Copyright>
                </FooterContainer>
            </AppContainer>
        </Router>
    );
};

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.div`
  flex-grow: 1;
`;

const FooterContainer = styled.div`
    height: 120px;
  background: #B3C6CB;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 10vw;
  font-family: 'Garamond', serif;
  letter-spacing:2px;
  font-size: 16px;

`;

const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DDLink = styled.a`
  color: #202D30;
  text-decoration: none;
`;

const Icon = styled.i`
  font-size: 1.5rem;
`;

const Copyright = styled.p`
  color: #202D30;
  text-align: center;
`;

export default App;

