/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import styled from "styled-components";
import { faqItems, FAQItem } from './assets/Data';
import { buttonStyle, fontFamily, pageHeight, subFontFamily } from './Contants';
const bullet = require('./assets/images/diamond_bullet.png');
//interface FAQItem {
//    question: string;
//    answer: string;
//}

//interface FAQProps {
//    items: FAQItem[];
//}

const FaqPage = () => {

    const title = "DAZZLING FAQs";
    const [selectedCollection, setSelectedCollection] = useState<FAQItem[]>(faqItems.Quality);
    const [currentSelection, setCurrentSelection] = useState("Quality");

    const handleCollectionClick = (collectionKey: any) => {
        switch (collectionKey) {
            case "Quality": setSelectedCollection(faqItems.Quality); setCurrentSelection("Quality"); break;
            case "Services": setSelectedCollection(faqItems.Services); setCurrentSelection("Services"); break;
            case "Warranty": setSelectedCollection(faqItems.Warranty); setCurrentSelection("Warranty"); break;
            case "WeAreDifferent": setSelectedCollection(faqItems.WeAreDifferent); setCurrentSelection("WeAreDifferent"); break;
            case "Billing": setSelectedCollection(faqItems.Billing); setCurrentSelection("Billing"); break;
            case "RRR": setSelectedCollection(faqItems.RRR); setCurrentSelection("RRR"); break;
            case "CustomerService": setSelectedCollection(faqItems.CustomerService); setCurrentSelection("CustomerService"); break;
            default: setSelectedCollection(faqItems.Services); setCurrentSelection("Services"); break;
        }
    }

    return (
        <Container>
            <h2>{title.replace(/ /g, '\u00A0')}</h2>
            <Navigation>
                <NavLink isActive={currentSelection === "Quality"} onClick={() => handleCollectionClick("Quality")}>Diamond Quality</NavLink>
                <NavLink isActive={currentSelection === "Services"} onClick={() => handleCollectionClick("Services")}>Service Offerings</NavLink>
                <NavLink isActive={currentSelection === "Warranty"} onClick={() => handleCollectionClick("Warranty")}>Warranty</NavLink>
                <NavLink isActive={currentSelection === "WeAreDifferent"} onClick={() => handleCollectionClick("WeAreDifferent")}>How are we different</NavLink>
                <NavLink isActive={currentSelection === "Billing"} onClick={() => handleCollectionClick("Billing")}>Billing & Transaction</NavLink>
                <NavLink isActive={currentSelection === "RRR"} onClick={() => handleCollectionClick("RRR")}>Returns, Refunds & Replacements</NavLink>
                <NavLink isActive={currentSelection === "CustomerService"} onClick={() => handleCollectionClick("CustomerService")}>Customer Service</NavLink>
            </Navigation>

            {selectedCollection?.map((item, index) => (
                <div key={index} style={{ marginTop: '3rem' }}>
                    <Question>
                        <Bullet />
                        {item.question}
                    </Question>
                    <Answer>{item.answer}</Answer>
                </div>
            ))}
        </Container>
    );
};

const Container = styled.div`
   height: auto;
  margin: 0 auto;
  padding: 20px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex-grow: 1; 

    h2 {
     ${fontFamily(false)}
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 4px;
    font-weight: 300;
}

     @media (max-width: 768px) {
          h2 {
            font-size: 1.2rem;
   }}
`;

const Question = styled.p`
  font-size: 1.5rem;
  margin-bottom: 0.8rem;
  ${subFontFamily(false)}
  letter-spacing:1.5px;
   display: flex;
  align-items: center;

   @media (max-width: 768px) {
            font-size: 1rem;
   }
`;

const Answer = styled.div`
  font-size: 1.5rem;
  margin-left: 30px;
  ${subFontFamily(true)}
  letter-spacing:1.5px;

    @media (max-width: 768px) {
            font-size: 1rem;
   }
`;

const Bullet = styled.img.attrs({
    src: bullet,
})`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(-100%);
    animation: shine 2s infinite linear;
  }

  @keyframes shine {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(100%);
    }
  }
`;

const Navigation = styled.nav`
display: flex;
margin-top: 20px;
  white-space: nowrap;
  flex-wrap: wrap;
  gap: 10px;

   @media (max-width: 768px) {
  gap: 0;
  flex-wrap: nowrap;
overflow-x: auto;
     margin-top: 10px;
     justify-content: left;
       &::-webkit-scrollbar {
    display: none;  // Hide scrollbar for Chrome, Safari and Opera 
  }

  -ms-overflow-style: none;  // Hide scrollbar for IE and Edge 
  }
    `;

const NavLink = styled.a<{ isActive: boolean }>`
${subFontFamily(false)}
  ${buttonStyle(true)}
    background-color:${props => props.isActive ? "white" : "black"};
    color:${props => props.isActive ? "black" : "white"};
    cursor: pointer ;
    padding: 10px 20px;
    margin:0 10px;

      @media (max-width: 768px) {
       flex-basis: 30%;
        margin:0.6vh 0.6vw;
   }
    `;

export default FaqPage;