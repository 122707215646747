
export interface FAQItem {
    question: string;
    answer: string;
}

interface FAQItems {
    [key: string]: FAQItem[];
}

//"Quality"} onClick = {() => h
//"Services"} onClick = {() =>
//"Warranty"} onClick = {() =>
//"WeAreDifferent"} onClick = {
//    "Billing"} onClick = {() => h
//"Customization"} onClick = {(
//    "RRR"} onClick = {() => handl
//"CustomerService"} onClick =

export const faqItems: FAQItems = {
    "Quality": [
        {
            question: 'How do you determine the quality and grading of your diamonds?',
            answer:
                'We use industry-standard grading criteria to assess the quality of our diamonds, including factors like the 4Cs (carat weight, cut, color, and clarity).',
        },
        {
            question: 'How are your diamonds sourced and acquired? Do you offer lab grown diamonds too?',
            answer:
                'We source our diamonds through reputable suppliers who adhere to strict ethical and environmental standards. Our diamonds come from conflict-free zones and are acquired through transparent supply chains. Yes, we do deal with CVD(Chemical Vapor Deposition) or Lab grown diamonds.',
        },
        {
            question: 'Do you provide certificates with detailed diamond specifications?',
            answer:
                'Yes, each of our diamonds comes with a certificate from a reputable gemological laboratory, providing detailed specifications and confirming its quality.',
        },
        {
            question: 'Can I request specific diamond certifications for my purchase?',
            answer:
                'Yes, you can request specific diamond certifications when making your purchase. We offer a range of certified diamonds to choose from.',
        },
    ],
    "Services": [
        {
            question: 'What types of jewelry can I have customized, and can I bring my own design ideas?',
            answer: 'We offer customization for a wide range of jewelry, including engagement rings, wedding bands, necklaces, bracelets, and more. You\'re encouraged to bring your design ideas, and our skilled artisans will bring your vision to life.'
        },
        {
            question: 'What gemstones can I choose, and do you offer vintage or antique reproductions?',
            answer: 'You can choose from a variety of gemstones, including diamonds, sapphires, rubies, and more. We specialize in recreating vintage and antique jewelry, preserving their timeless beauty with modern techniques.'
        },
        {
            question: 'How long does it typically take for a custom jewelry order, and can you replicate from a photograph or description?',
            answer: 'Custom order timelines vary (typically 1 week to a month) based on complexity. We can replicate jewelry from a photograph or description, ensuring we capture the essence and details of the piece you desire.'
        },
        {
            question: 'Do you offer resizing for existing jewelry pieces, and how do you ensure security during customization?',
            answer: 'Yes, we provide resizing services for a perfect fit. Your jewelry is handled with care, and secure storage options are provided during customization. Additionally, your jewelry is insured throughout the process.'
        },
        {
            question: 'Can I see a 3D rendering before my jewelry is made, and are there additional charges for customization and design consultation?',
            answer: 'Yes, we offer 3D renderings for you to visualize the final piece. Design consultations are free, and our customization charges are transparent. Any additional costs, if applicable, will be communicated upfront.'
        }
    ],
    "Warranty": [
        {
            question: 'What kind of warranty do you offer for diamond jewelry?',
            answer: 'We provide a comprehensive warranty covering manufacturing defects and craftsmanship issues for a specified period.',
        },
        {
            question: 'How long is the warranty period for diamond jewelry?',
            answer: 'Our warranty typically lasts for 5 years, ensuring the quality and durability of your purchase.',
        },
        {
            question: 'What does the warranty cover?',
            answer: 'Our warranty covers defects in materials or workmanship, including loose gemstones, prong damage, and manufacturing defects.',
        },
        {
            question: 'What is not covered by the warranty?',
            answer: 'The warranty excludes damage due to wear and tear, accidents, gemstone loss, or alterations by third-party jewelers. Please refer to our terms for specific details.',
        },
        {
            question: 'How do I register for warranty and make a claim?',
            answer: 'To make a claim, contact us with proof of purchase, and we\'ll guide you through the process. It is as simple as showing the purchase certificate which we maintain a copy of, at our end too.',
        },
        {
            question: 'Can I purchase an extended warranty?',
            answer: 'Yes, we offer extended warranty options for additional peace of mind. Please inquire about our extended warranty plans through our customer support team.',
        },
    ],
    "WeAreDifferent": [
        {
            question: 'What sets your diamond company apart from others in the market?',
            answer: 'We are committed to offering the best pricing in the industry while maintaining exceptional quality. Our goal is to provide a luxurious diamond ownership experience at the most affordable prices, catering to a wider audience rather than targeting only the elite class.'
        },
        {
            question: 'How do you ensure the high quality of your diamonds that customers won\'t complain about?',
            answer: 'Our diamonds undergo rigorous quality control processes to ensure they meet the highest standards. We source diamonds responsibly and handpick each gemstone. Our skilled artisans craft jewelry with precision, delivering quality that exceeds expectations.'

        },
        {
            question: 'Do you offer replicas of high-end brands, and how do your prices compare?',
            answer: 'Yes, we specialize in creating replicas of high-end brands at a fraction of the price. Our commitment to affordability does not compromise quality. You can enjoy the luxury of renowned brands without breaking the bank.'
        },
        {
            question: 'What makes your warranty and return policy stand out from others?',
            answer: 'Our warranty and return policy are unparalleled. We cover manufacturing defects and craftsmanship issues, ensuring your peace of mind. If you\'re not satisfied, our return process is hassle- free, prioritizing customer satisfaction.'
        },
        {
            question: 'Can you explain your approach to diamond certification and customer transparency?',
            answer: 'We believe in full transparency. Every diamond purchase comes with internationally recognized certificates, providing you with detailed specifications. Our goal is to empower customers with information, so you can make confident choices.'
        },
        {
            question: 'How do you ensure that your customers find the best diamonds at the cheapest prices?',
            answer: 'Our commitment to mass accessibility drives us to keep prices competitive without compromising on quality. By prioritizing affordability and offering unbeatable value, we ensure you find the perfect diamond without overspending.'
        },
    ],
    "Billing": [
        {
            question: 'What is your shipping policy for orders within India?',
            answer: 'We offer free shipping for orders within India. Your purchase will be delivered to your doorstep at no additional cost.'
        },
        {
            question: 'Do you provide international shipping?',
            answer: 'Yes, we offer international shipping to customers outside India. However, international shipping may incur additional charges due to distance and customs regulations.'
        },
        {
            question: 'How are international shipping charges determined?',
            answer: 'Determining international shipping charges can vary based on the destination, package weight, and customs regulations. Our customer support team will assist you in calculating the shipping cost for your specific order.'
        },
        {
            question: 'Is international shipping expensive for single jewelry pieces?',
            answer: 'International shipping costs can be relatively higher for single jewelry pieces due to packaging, insurance, and customs fees. We recommend considering multiple purchases or reaching out to our team to explore cost-effective shipping options.'
        },
        {
            question: 'What payment methods do you accept?',
            answer: 'We accept a range of payment methods, including credit cards, debit cards, net banking, and online wallets. Your transactions are secure and handled through our registered company for payments.'
        },
        {
            question: 'Can I receive a receipt for my purchase?',
            answer: 'Certainly! We provide internationally recognized certifications such as GIA (Gemological Institute of America) certificates for all our diamond purchases. This certification serves as a receipt of your purchase, and we keep a copy for our records to assist you in the future.'
        }
    ],
    "RRR": [
        {
            question: 'What is your policy on returns, refunds, and replacements?',
            answer: 'We have a customer-centric approach. If the fault is on our side, we gladly accept returns and replacements, offering a full refund. Additionally, if you wish to change or sell your purchase, we are here to assist. For real diamonds, we ensure that you won\'t lose more than 10% of the day of purchase value, regardless of market fluctuations.For lab- grown diamonds, prices are based on the ongoing market rates.We are dedicated to giving you the best price in the market, making this an investment you can trust.'
        },

        {
            question: 'How does the upgrade process work?',
            answer: 'We welcome upgrades and offer them at the current market price. The advantage of dealing with us is that for real diamonds, your maximum loss in value, even if the market falls by 50%, is capped at 10%. This policy ensures your investment remains secure over time.'
        },

        {
            question: 'Can I trust that I\'ll receive the best price when selling my jewelry back to you?',
            answer: 'Absolutely! You can trust us to provide the best price in the market when you decide to sell or make changes to your jewelry. We prioritize fairness and transparency in all our transactions.'
        }

    ],
    "CustomerService": [
        {
            question: 'How can I contact your customer service team?',
            answer: 'Our customer service team is readily available through multiple channels. You can reach us via phone, email, or through the contact form on our website. We are here to assist you with any questions or concerns.'
        },

        {
            question: 'What are your customer service hours?',
            answer: 'Our customer service team is available 24X7. We are committed to providing timely assistance to our valued customers.'
        },
        {
            question: 'Do you provide assistance with jewelry selection and customization?',
            answer: 'Absolutely! Our experienced team is here to guide you through the process of selecting, customizing, and personalizing your jewelry. We are dedicated to helping you find the perfect piece that suits your style and preferences.'
        },
        {
            question: 'What if I have questions about the certification of my diamond?',
            answer: 'If you have any questions or require information about the certification of your diamond, our customer service team is well-equipped to provide detailed explanations and ensure you have complete clarity.'
        },
        {
            question: 'Can I get assistance with jewelry care and maintenance?',
            answer: 'Yes, we offer guidance and services for jewelry care and maintenance. Feel free to contact our customer service team for advice on preserving the beauty and quality of your jewelry.'
        },

        {
            question: 'What should I do if I encounter an issue with my purchase?',
            answer: 'If you encounter any issues with your purchase, please don\'t hesitate to reach out to our customer service team.We will promptly address your concerns and work towards a resolution that ensures your satisfaction.'
        }
    ],
};

const necklaces: string[] = [];
for (let i = 1; i <= 29; i++) {
    necklaces.push(require(`./images/necklace/necklace (${i}).jpg`));
}

const earrings: string[] = [];
for (let i = 1; i <= 26; i++) {
    earrings.push(require(`./images/earring/earring (${i}).jpg`));
}

const bracelets: string[] = [];
for (let i = 1; i <= 16; i++) {
    bracelets.push(require(`./images/bracelet/bracelet (${i}).JPG`));
}

const rings: string[] = [];
for (let i = 1; i <= 40; i++) {
    rings.push(require(`./images/ring/ring (${i}).JPG`));
}

export { necklaces };
export { earrings };
export { bracelets };
export { rings };