/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import styled from "styled-components";
import { earrings, faqItems, necklaces, rings, bracelets } from './assets/Data';
import { buttonStyle, fontFamily, pageHeight, subFontFamily } from './Contants';
const braceletHero = require('./assets/images/bracelet/braceletHero.JPG');
const necklaceHero = require('./assets/images/necklace/necklaceHero.jpg');
const earringHero = require('./assets/images/earring/earringHero.jpg');
const ringHero = require('./assets/images/ring/ringHero.JPG');

type CollectionsType = {
    [key: string]: [string, string, string[]];
}

const CollectionPage = () => {

    const [selectedCollection, setSelectedCollection] = useState("ringCollection");
    const [selectedCollectionHero, setselectedCollectionHero] = useState(ringHero);
    const [selectedCollectionHeroText, setselectedCollectionHeroText] = useState("Forever\nYours");
    const [selectedImage, setSelectedImage] = useState(null);

    const collections: CollectionsType = {
        ringCollection: [ringHero, "Forever\nYours", rings],
        necklaceCollection: [necklaceHero, "Timeless\nGrace", necklaces],
        earringCollection: [earringHero, "Radiant\nCharm", earrings],
        braceletCollection: [braceletHero, "Eternal\nBond", bracelets],
    }

    const handleCollectionClick = (collectionKey: any) => {
        const collection = collections[collectionKey];
        setSelectedCollection(collectionKey);
        setselectedCollectionHero(collection[0]);
        setselectedCollectionHeroText(collection[1]);
    }

    const handleImageClick = (image: any) => {
        setSelectedImage(image);
    }

    const handleModalClose = () => {
        setSelectedImage(null);
    }

    return (
        <Container>
            <Navigation>
                <NavLink isActive={selectedCollection === "ringCollection"} onClick={() => handleCollectionClick("ringCollection")}>Rings</NavLink>
                <NavLink isActive={selectedCollection === "necklaceCollection"} onClick={() => handleCollectionClick("necklaceCollection")}>Necklaces</NavLink>
                <NavLink isActive={selectedCollection === "earringCollection"} onClick={() => handleCollectionClick("earringCollection")}>Earrings</NavLink>
                <NavLink isActive={selectedCollection === "braceletCollection"} onClick={() => handleCollectionClick("braceletCollection")}>Bracelets</NavLink>
            </Navigation>

            <CollectionContainer>
                <HeroImageContainer>
                    <HeroImage src={selectedCollectionHero} alt="Hero Image" />
                    <HeroImageText>{selectedCollectionHeroText}</HeroImageText>
                </HeroImageContainer>
                {collections[selectedCollection][2].map((image: any) => (
                    <JewelryImage key={image} src={image} alt="Jewelry Image" onClick={() => handleImageClick(image)} />
                ))}
            </CollectionContainer>

            {selectedImage && (
                <Modal onClick={handleModalClose}>
                    <ModalContent>
                        <CloseButton onClick={handleModalClose}>X</CloseButton>
                        <img src={selectedImage} alt="Selected Jewelry Image" />
                    </ModalContent>
                </Modal>
            )}
        </Container>
    );
};

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
margin: 0 auto;
padding: 20px;
color: #FFFFFF;
`;

const HeroImage = styled.img`
width: 100 %;
height: 300px;
object-fit: cover;
  width: 100%;
  height: 100%;
  object-fit: cover;
  grid-column: 1 / span 2;
    grid-row: span 2;
    filter: grayscale(100%);
`;

const Navigation = styled.nav`
display: flex;
justify-content: center;
margin-top: 20px;
overflow-x: auto;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;  // Hide scrollbar for Chrome, Safari and Opera 
  }

  -ms-overflow-style: none;  // Hide scrollbar for IE and Edge 

   @media (max-width: 768px) {
     margin-top: 10px;
     justify-content: left;
  }
    `;

const NavLink = styled.a<{ isActive: boolean }>`
${subFontFamily(false)}
  ${buttonStyle(true)}
    background-color:${props => props.isActive ? "white" : "black"};
    color:${props => props.isActive ? "black" : "white"};
    cursor: pointer ;
    padding: 10px 20px;
    margin:0 10px;

      @media (max-width: 768px) {
       flex-basis: 30%;
    padding: 10px 10px;
    margin:0.6vh 0.6vw;
   }
    `;

const CollectionContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  margin-top: 2rem;

   @media (max-width: 768px) {
       grid-template-columns: repeat(4, 1fr);
   }
`;

const HeroImageContainer = styled.div`
  position: relative;
  grid-column: 1 / span 2;
  grid-row: span 2;
`;


const HeroImageText = styled.div`
  ${subFontFamily(false)}
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  color: #69601D;
  font-weight:500;
  font-size:2rem;
   white-space: pre-line; //this helps break the word separated by \n in string
  animation: fadeInOut 14s linear 1;

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  
   @media (max-width: 768px) {
  font-size:1.2rem;
  top: 5px;
  right: 5px;
   }
`;



const JewelryImage = styled.img`
object-fit: cover;
border-radius: 5px;
  width: 100%;
  height: 100%;
`;

const Modal = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
`;

const ModalContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    padding: 50px;
    z-index: 2;
    max-width: 600px;
    max-height: 600px;
    height: auto; // Set height to a percentage of the viewport
    box-sizing: border-box;

     img {
        width: 100%; // Image will take up full width of ModalContent
        height: auto; // Height will scale proportionally
        object-fit: contain;
    }

    @media (max-width: 768px) {
        width: 70vw; // Increase width for smaller screens
        height: auto;
   }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #FFFFFF;
  color: #202D30;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: none;
  box-shadow: inset -4px -4px 8px rgba(255,255,255,0.5), 
              inset 4px 4px 8px rgba(0,0,0,0.1);
`;


export default CollectionPage;