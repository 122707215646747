/* eslint-disable @typescript-eslint/no-unused-vars */
import logo from './logo.svg';
import './App.css';

import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Slider from 'react-slick';
import { buttonStyle, fontFamily, subFontFamily } from './Contants';
import { useNavigate } from 'react-router-dom';


const diamond1 = require('./assets/images/1.JPG');
const diamond2 = require('./assets/images/2.JPG');
const diamond3 = require('./assets/images/3.JPG');
const diamond4 = require('./assets/images/4.JPG');
const diamond5 = require('./assets/images/5.JPG');
const diamond6 = require('./assets/images/6.JPG');
const HeaderBackground = require('./assets/images/DiamondsFalling.png');
const DDLogo = require('./assets/images/dd_logo.png');
const leftQuote = require('./assets/images/leftQuote.png');
const rightQuote = require('./assets/images/rightQuote.png');
const diamond = require('./assets/images/diamond.png');
const diamondPieces = require('./assets/images/diamond_pieces.png');

interface MyProps {
    delay?: number;
    isDark?: boolean;
    alignRight?: boolean;
}

const Home = () => {

    const navigate = useNavigate();

    useEffect(() => {
        const h1Element = document.querySelector('h1');
        if (h1Element) { h1Element.style.opacity = '1'; }
    }, []);


    const imagesArray = [
        diamond1,
        diamond2,
        diamond3,
        diamond4,
        diamond5,
        diamond6,
    ];

    const companyName = "DIVNOR  DIAMONDS";
    const text = "Dreams to Diamonds";
    const words = text.split(' ');

    const feedbacks = [
        "This product exceeded my expectations and am extremely happy to score a great price!",
        "I highly recommend this service to everyone.",
        "The customer support was exceptional.",
        "I'm extremely satisfied with my purchase.",
        "The quality of the product is outstanding.",
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <Container>
            <HeaderContent onClick={() => navigate('/aboutus')} >
                <img src={DDLogo} alt={DDLogo} />
                <h2>{companyName.replace(/ /g, '\u00A0')}</h2>
                <p>
                    Crafted by expert artisans from India, Divnor Diamonds offers high-quality custom jewelry at reasonable prices. We create stunning one-of-a-kind pieces, always prioritizing precision and quality.
                    Turn your imagination into reality with our skillful craftsmanship. We specialize in replicating luxury brands and bringing unique designs to life. You dream it, we craft it. <a>learn more</a>               </p>
            </HeaderContent>
            <Products>
                {imagesArray.map((image) => (
                    <Product key={image}>
                        <img src={image} alt={image} />
                    </Product>
                ))}
                <ProductsButton>
                    <Button onClick={() => navigate('/collection')}>See all collections</Button>
                </ProductsButton>
            </Products>
            <FeedbackContainer>
                <FeedbackSubcontainer>
                    <QuoteImage src={leftQuote} />
                </FeedbackSubcontainer>
                <CarouselContainer>
                    <Carousel {...settings}>
                        {feedbacks.map((feedback, index) => (
                            <Feedback key={index}>{feedback}</Feedback>
                        ))}
                    </Carousel>
                </CarouselContainer>
                <FeedbackSubcontainer>
                    <QuoteImage alignRight src={rightQuote} />
                </FeedbackSubcontainer>
            </FeedbackContainer>
            <InfoContainer>
                <GetQuotes onClick={() => navigate('/getquote')}>
                    <Heading>GET QUOTES</Heading>
                    <Text>Why wait for luxury? Request a quote today and start turning your dream jewelry into a sparkling reality.</Text>
                    <Button onClick={() => navigate('/getquote')}>Get quotes</Button>
                </GetQuotes>
                <Faq onClick={() => navigate('/faq')}>
                    <Heading>F.A.Q</Heading>
                    <Text>Explore and find answers to all your diamond inquiries. Get all the details you need about our exquisite diamonds and services.</Text>
                    <Button onClick={() => navigate('/faq')}>Learn more</Button>
                </Faq>
            </InfoContainer>
        </Container>
    );
};

const rotate = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
`;

const RevolvingDiamond = styled.img`
  width: 7rem;
  animation: ${rotate} 50s linear infinite;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #202D30;
  font-weight:300;
`;

const Header = styled.div`
  background-size: cover;
  background-position: center;
  position: relative;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const H1 = styled.h1`
  overflow: hidden;
        font-family: 'Garamond', serif;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 1rem;
    font-style: italic;
    color: #202D30;
    font-weight: 300;
`;

const LetterAppearAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Letter = styled.span<MyProps>`
  display: inline-block;
  animation: ${LetterAppearAnimation} 0.5s ease-in-out forwards;
  opacity: 0;
  animation-delay: ${props => props.delay}s;
`;

const HeaderContent = styled.div`
  color: lightgray;
  text-align: center;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  cursor: pointer;
  img {
    max-width: 80px;
    height: auto;
    vertical-align: middle;
  }
  h2 {
    ${fontFamily(false)}
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 4px;
    font-weight: 300;
  }
  p {
    font-family: 'Garamond', serif;
    margin-bottom: 20px;
    padding: 0px 10vw;
    text-align: justify;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    line-height:1.5rem;
    a{
      color: #B3C6CB;
      ${subFontFamily(true)}
    }
  }
  @media (max-width: 600px) {
    margin-top: 1rem;
    img {
        max-width: 70px;
    }
    h2 {
        font-size: 1.4rem;
    }
    p {
        padding: 0px 8vw;
        font-size: 1.1rem;
    }
  }
  @media (max-width: 400px) {
    margin-top: 1rem;
    img {
        max-width: 60px;
        font-size: 1rem;
    }
    h2 {
        font-size: 1.2rem;
    }
    p {
        padding: 0px 7vw;
    }
  }
`;
const Button = styled.button<MyProps>`
   ${fontFamily(true)}
  ${buttonStyle(false)}
`;

const Products = styled.div`
  margin-top: 3vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0px 5vw;   

   @media (max-width: 600px) {
    padding: 0px 2vw;   
  }
   @media (max-width: 600px) {
    padding: 0px 2vw;   
  }
`;

const Product = styled.div`
  width: 25vw;
  margin: 1vw;
  text-align: center;

  img {
    width: 100%;
    height: auto;
  }
   @media (max-width: 600px) {
  width: 40vw;
  }
  @media (max-width: 400px) {
  width: 40vw;
  }
`;

const ProductsButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5vh 25vw;

  @media (max-width: 600px) {
  padding: 5vh 7vw;
  }
  @media (max-width: 400px) {
  padding: 4vh 6vw;
  }
`;

const FeedbackSubcontainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const QuoteImage = styled.img<MyProps>`
  align-self: ${props => (props.alignRight ? 'flex-end' : 'flex-start')};
  margin-left: ${props => (props.alignRight ? 'auto' : '')};
`;

const CarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Feedback = styled.div`
  display: flex;
  font-family: 'Garamond', serif;
  text-align: center;
  padding: 5vh 2vw 5vh 2vw;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  max-width: calc(100% - 6vw); /* Without this, the word break doesn't work */
    font-size: 1.2rem;
   letter-spacing: 0.5px;
   line-height:1.5rem;
`;

const Carousel = styled(Slider)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Set the width to 100% to go full width */
  height: 50%; /* Set the height to 100% to fill the container */
  color: white;

  .slick-prev,
  .slick-next {
    display: none !important; /* Hide the side arrows */
  }

  .slick-dots {
    bottom: 10px;
  }

  .slick-dots li button:before {
    font-size: 12px;
    color: #B3C6CB;
  }

  .slick-dots li.slick-active button:before {
    color: #fff;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  @media (max-width: 600px), (max-width: 400px) {
    flex-direction: column;
  }
`;

const GetQuotes = styled.div`
  flex: 1;
  background-color: #DFE7EE;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 0 ;
`;

const Faq = styled.div`
  flex: 1;
  background-color: #E5DBE0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 0 ;
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  //margin-bottom: 10px;
  font-weight:300;
   ${fontFamily(true)}
`;

const Text = styled.p`
  //margin-bottom: 10px;
  font-family: 'Garamond', serif;
  padding: 1rem 2vw;
    font-size: 1.2rem;
   letter-spacing: 0.5px;
   line-height:1.5rem;
`;

export default Home;
