/* eslint-disable @typescript-eslint/no-unused-vars */
import './App.css';

//import React, { useState } from "react";
import React, { useEffect, useMemo, useState } from "react";
import styled, { keyframes } from "styled-components";
import { buttonStyle, fontFamily } from './Contants';
import { useNavigate } from 'react-router-dom';
//import 'slick-carousel/slick/slick.css';
//import 'slick-carousel/slick/slick-theme.css';

const diamond1 = require('./assets/images/1.JPG');
const diamond2 = require('./assets/images/2.JPG');
const diamond3 = require('./assets/images/3.JPG');
const diamond4 = require('./assets/images/4.JPG');
const diamond5 = require('./assets/images/5.JPG');
const diamond6 = require('./assets/images/6.JPG');
const HeaderBackground = require('./assets/images/DiamondsFalling.png');

interface HeaderProps {
    delay?: number;
    isDark?: boolean;
    alignRight?: boolean;
}

const HeaderHome = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const h1Element = document.querySelector('h1');
        if (h1Element) { h1Element.style.opacity = '1'; }
    }, []);

    const [displayedText, setDisplayedText] = useState<string>('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setDisplayedText(aboutUs[index]);
            setIndex((i) => (i + 1) % aboutUs.length);
        }, 6000);
        return () => clearInterval(interval);
    }, [index]);

    const imagesArray = [
        diamond1,
        diamond2,
        diamond3,
        diamond4,
        diamond6,
        diamond5,
    ];
    const text = "Dreams to Diamonds";
    const words = text.split(' ');
    const aboutUs = [
        "Where Dreams Sparkle, We Create - Your Vision, Our Craftsmanship",
        "Iconic Designs Reimagined Just for You - Bring Us Your Inspiration",
        "Elevating Elegance with Unbeatable Value - Quality Diamonds, Affordable Brilliance",
        "From India's Heart to Your Doorstep - Global Shipping, Local Artistry",
        "Shop with Confidence - Our Returns Policy Ensures Your Satisfaction",
        "Certified Brilliance, Trusted Quality - Authenticity Guaranteed",
        "Your Satisfaction, Our Purpose - Experience Excellence with Every Purchase"
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const generateLetters = (word: string) => {
        return word.split('').map((letter: string, letterIndex: number) => (
            <Letter key={letterIndex} delay={0.4 + letterIndex * 0.08}>
                {letter}
            </Letter>
        ));
    };

    const renderedWords = useMemo(() => {
        return words.map((word, wordIndex) => (
            <React.Fragment key={wordIndex}>
                {generateLetters(word)}
            </React.Fragment>
        ));
    }, [words]);

    return (
        <Container>
            <Header>
                <H1>{renderedWords}</H1>
                <Content>
                    <Button isDark onClick={() => navigate('/collection')}>SEE COLLECTIONS</Button>
                    <Text key={displayedText}>{displayedText}</Text>
                </Content>

                {/*<FallingDiamond src={diamond} alt="Diamond" />*/}
                {/*<RevolvingDiamond src={diamond} alt="Diamond" />*/}
            </Header>
        </Container>
    );
};

const rotate = keyframes`
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #202D30;
  font-weight:300;
`;

const Header = styled.div`
  background-size: cover;
  background-position: center;
  position: relative;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${HeaderBackground});
    /* Add the image-rendering property to optimize image rendering */
  image-rendering: pixelated;
`;

const H1 = styled.h1`
  overflow: hidden;
   font-family: 'Garamond', serif;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 1rem;
    font-style: italic;
    color: #202D30;
    font-weight: 300;

    @media (max-width: 600px) {
    font-size: 2rem;
    margin-bottom: 5px;
    letter-spacing: 0.5rem;
  }

  @media (max-width: 400px) {
    font-size: 1.5rem;
    margin-bottom: 3px;
    letter-spacing: 0.5rem;
  }
`;

const LetterAppearAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Letter = styled.span<HeaderProps>`
  display: inline-block;
  animation: ${LetterAppearAnimation} 0.5s ease-in-out forwards;
  opacity: 0;
  animation-delay: ${props => props.delay}s;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  @media (max-width: 600px) {
  height: 60px;
  }
  @media (max-width: 400px) {
  height: 50px;
  }
`;

const Button = styled.button<HeaderProps>`
  ${fontFamily(true)}
  ${buttonStyle(true)}

  @media (max-width: 600px) {
    font-size: 0.8rem;
  }
  @media (max-width: 400px) {
    font-size: 0.7rem;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const FadeInOut = styled.div`
  animation: ${fadeIn} 2s linear 2s, ${fadeOut} 2s linear 4s;
`;

const Text = styled(FadeInOut)`
    opacity: 0;
    margin-top: 50px;
    font-family: 'Garamond', serif;
    font-size: 1.4rem;
    font-style: italic;
    letter-spacing: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-justify: inter-word;

    @media (max-width: 600px) {
    margin-top: 30px;
    font-size: 1rem;
  }
  @media (max-width: 400px) {
    margin-top: 25px;
    font-size: 0.8rem;
  }
`;

export default HeaderHome;
