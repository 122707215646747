import { css } from 'styled-components';

export const fontFamily = (cursive: boolean) => css`
  font-family: 'Cinzel Decorative', ${cursive ? 'cursive' : 'serif'};
`;

export const subFontFamily = (cursive: boolean) => css`
  font-family: 'Garamond';
  font-style: ${cursive ? 'italic' : ''};
`;

export const pageHeight = () => css`
 height: 76vh;
`;

export const buttonStyle = (isDark: boolean) => css`
  font-family: 'Nunito', sans-serif;
  font-family: 'Garamond', serif;
  background-color: ${isDark ? '#202D30' : '#FFFFFF'}; 
  border: none;
  border-radius: 20px;
  font-size: 1rem;
  margin-top: 25px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  //max-width: 250px;
  color:${isDark ? '#FFFFFF' : '#202D30'};
  
  &:active {
    box-shadow: ${isDark ? 'inset 6px 6px 12px #ffffff, inset -6px -6px 12px #d9d9d9' : 'inset 6px 6px 12px #d9d9d9, inset -6px -6px 12px #ffffff'}; 
  }
`;
