/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import styled from "styled-components";
import { faqItems } from './assets/Data';
import { fontFamily, subFontFamily } from './Contants';
const necklace = require('./assets/images/necklace_format.png');
interface GridItemProps {
    title: string;
    description: string;
}

const GridItemComponent: React.FC<GridItemProps> = ({ title, description }) => (
    <GridItem>
        <GridItemTitle>{title}</GridItemTitle>
        <GridItemDescription>{description}</GridItemDescription>
    </GridItem>
);


const AboutUsPage = () => {
    const title = "DIVNOR'S STORY";

    return (
        <PageContainer>
            <h2>{title.replace(/ /g, '\u00A0')}</h2>
            <Grid>
                <GridItemComponent title="Remarkable Quality" description="Our Indian artisans hand-pick the finest diamonds for each bespoke creation." />
                <GridItemComponent title="Competitive Pricing" description="Experience luxury without breaking the bank, thanks to our direct sourcing. " />
                <GridItemComponent title="Speedy Turnaround" description="Expect your stunning custom piece to be ready in record time." />
                <GridItemComponent title="Designer Replicas" description="Fancy a famous design? We'll create an identical look at a fraction of the price." />
                <GridItemComponent title="Unique Creations" description="Bring your impagination to life with our expert team's guidance." />
                <GridItemComponent title="Captivating Variety" description="Our impressive collection offers luxurious choices for every taste." />
            </Grid>
            <Image src={necklace} alt="Image" />
            <Paragraph>At Divnor Diamonds, we're not just in the business of crafting exquisite jewelry; we're in the business of creating lasting memories. With a passion for brilliance and a commitment to quality, we embark on a journey to bring the world's most exquisite diamonds to life. Our story is one of heritage and innovation, where tradition meets modernity, and every piece we create is a testament to our dedication to beauty and elegance.</Paragraph>
            <Paragraph>Established with a vision to redefine the diamond experience, Divnor Diamonds is a name synonymous with authenticity, craftsmanship, and excellence. Our journey begins with ethically sourced diamonds that are meticulously handpicked to adorn our designs. Each diamond we curate tells a unique story, and we take pride in the responsible practices that underpin every stone in our collection</Paragraph>
            <Paragraph>Our artisans, true masters of their craft, breathe life into each design, transforming raw materials into stunning works of art. From classic solitaires to intricate custom pieces, our jewelry is a celebration of the extraordinary moments that define our lives. Beyond the aesthetics, our jewelry embodies emotions, milestones, and cherished memories.</Paragraph>
            <Paragraph>At Divnor Diamonds, we believe that our customers deserve nothing but the best, and that's why we tirelessly strive for excellence in every facet of our work. From the initial spark of inspiration to the final gleam of a polished gemstone, our journey is driven by a commitment to quality, transparency, and customer satisfaction.</Paragraph>
            <Paragraph>As we continue to evolve, our dedication to craftsmanship, ethical practices, and customer-centric values remains unwavering. Join us in the world of Divnor Diamonds, where every piece of jewelry is a masterpiece, and every customer becomes a part of our enduring legacy.</Paragraph>
        </PageContainer>
    );
};

const PageContainer = styled.div`
  padding: 1rem;
   margin: 0 auto;
  max-width: 90vw;
  color: #FFFFFF;
     ${subFontFamily(false)}

   h2 {
     ${fontFamily(false)}
     font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 4px;
    font-weight: 300;
    }

     @media (max-width: 768px) {
       h2 {
     font-size: 1.2rem;
     }
  }
`;

const Title = styled.h3`
  text-align: center;
`;

const Image = styled.img`
  width: calc(100% - 10px);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 0; grid-column-gap: 10px;
  margin-top: 2rem 0;
   @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    margin: 1rem 0;
  }
`;

const GridItem = styled.div``;

const GridItemTitle = styled.h3`
${subFontFamily(false)}
     font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2px;
    font-weight: 300;

   @media (max-width: 768px) {
     font-size: 1rem;
     letter-spacing: 2px;
   }
`;

const GridItemDescription = styled.p`
font-size: 1.2rem;
   letter-spacing: 1px;
   line-height:1.5rem;
     ${subFontFamily(true)}
     
   @media (max-width: 768px) {
     font-size: 0.9rem;
       letter-spacing: 0.7px;
       line-height:1.2rem;
       }
   `;

const Paragraph = styled.p`
     ${subFontFamily(false)}
   font-size: 1.2rem;
   letter-spacing: 0.5px;
   line-height:1.5rem;
   text-align: justify;
`;


export default AboutUsPage;