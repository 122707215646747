/* eslint-disable @typescript-eslint/no-unused-vars */
import logo from './logo.svg';
import './App.css';

//import React, { useState } from "react";
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Slider from 'react-slick';
import { fontFamily } from './Contants';
import { useNavigate } from 'react-router-dom';

const diamond1 = require('./assets/images/1.JPG');
const diamond2 = require('./assets/images/2.JPG');
const diamond3 = require('./assets/images/3.JPG');
const diamond4 = require('./assets/images/4.JPG');
const diamond5 = require('./assets/images/5.JPG');
const diamond6 = require('./assets/images/6.JPG');
const HeaderBackground = require('./assets/images/DiamondsFalling.png');
const DDLogo = require('./assets/images/dd_logo.png');

interface MyProps {
    delay?: number;
    isDark?: boolean;
    alignRight?: boolean;
}

const Headerx = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const h1Element = document.querySelector('h1');
        if (h1Element) { h1Element.style.opacity = '1'; }
    }, []);

    const companyName = "DIVNOR  DIAMONDS";
    const text = "Dreams to Diamonds";
    const words = text.split(' ');

    return (
        <Container>
            <Header>
                <div>
                    <img src={DDLogo} alt={DDLogo} style={{ width: '60px', height: '60px' }} onClick={() => navigate('/')} />
                    <h2 onClick={() => navigate('/')}>{companyName.replace(/ /g, '\u00A0')}</h2>
                </div>
            </Header>
        </Container>
    );
};

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #202D30;
  font-weight:300;
`;

const Header = styled.div`
  background-size: cover;
  background-position: center;
  position: relative;
  height: 12vh;
  background-image: url(${HeaderBackground});
      /* Add the image-rendering property to optimize image rendering */
  image-rendering: pixelated;

  div{
      display: flex;
      align-items: center;
      padding: 1rem 2rem;
    cursor: pointer;
  }

    h2 {
      ${fontFamily(false)}
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 4px;
    font-weight: 300;
    margin-left: 1rem;
}

      @media (max-width: 768px) {
        h2 {
    font-size: 1.2rem;
    margin-left: 0.5rem;
            }

            div{
      padding: 1rem 1rem;
  }
   }
`;

export default Headerx;
