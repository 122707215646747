/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { SetStateAction, useRef, useState } from 'react';
import styled from "styled-components";
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { faqItems } from './assets/Data';
import { fontFamily, pageHeight, subFontFamily, buttonStyle } from './Contants';
const heroImageGetQuotes = require('./assets/images/ring.png');
const heroImageContactUs = require('./assets/images/contactus.png');
interface GetQuotesProps {
    route: string;
}
interface TemplateParams {
    name?: string;
    email?: string;
    phone?: string;
    message?: string;
    attachment?: any;
}

const YOUR_SERVICE_ID = 'service_r68h8li';
const YOUR_TEMPLATE_ID = 'DD_GetQuotes';
const YOUR_PUBLIC_KEY = 'X8U1vuT8hIDJQiuVX';

const GetQuotePage: React.FC<GetQuotesProps> = ({ route }) => {

    const titleGetQuotes = "DIVNOR'S SPARKLING QUOTES";
    const titleContactus = "DIVNOR'S TOUCH";
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [attachment, setAttachment] = useState<SetStateAction<any>>(null);

    const [errors, setErrors] = useState<TemplateParams>({
        name: '',
        attachment: null,
        email: '',
        message: '',
        phone: ''
    });

    const buildPageHeader = () => {
        if (route === 'getquote') {
            return (
                <>
                    <h2>{titleGetQuotes.replace(/ /g, '\u00A0')}</h2>
                    <p>Ready for a glimpse into the mesmerizing realm of diamond valuation? Let our experts craft a customized quote for your precious stones. Do not miss the chance to unlock the dazzling potential of your assets!</p>
                </>
            );
        }
        return (
            <>
                <h2>{titleContactus.replace(/ /g, '\u00A0')}</h2>
                <p>We would love to hear from you! Whether you have a question about our diamonds, need assistance with a purchase, or just want to share your thoughts, our team is here to help. Fill out the form below to get in touch with us and we will get back to you as soon as possible. We look forward to hearing from you!</p>
            </>
        );
    };

    const buildMoreDetails = () => {
        if (route === 'getquote') {
            return (
                <img src={heroImageGetQuotes} alt={heroImageGetQuotes} />
            );
        }
        return (
            <>
                <ContactContainer>
                    <ContactContent>
                        <Row>
                            <Icon className="material-icons">phone</Icon>
                            <p>Let's talk</p>
                        </Row>
                        <Row><ContactInfo>+91 91045 00510</ContactInfo></Row>
                    </ContactContent>
                    <ContactContent>
                        <Row>
                            <Icon className="material-icons">group</Icon>
                            <p>Socialize</p>
                        </Row>
                        <DDLinkRow>
                            <DDLink href="https://www.instagram.com/divnordiamonds/" target="blank">Instagram: @divnordiamonds</DDLink>
                            <DDLink href="https://www.facebook.com/groups/divnordiamonds" target="blank">Facebook: @Divnor Diamonds </DDLink>
                        </DDLinkRow>
                    </ContactContent>
                    <ContactContent>
                        <Row>
                            <Icon className="material-icons">mail</Icon>
                            <p>General support</p>
                        </Row>
                        {/*<Row><ContactInfo>divnordiamonds@gmail.com</ContactInfo></Row>*/}
                        <Row>   <DDLink href="mailto:divnordiamonds@gmail.com" target="blank">
                            divnordiamonds@gmail.com
                        </DDLink></Row>
                    </ContactContent>
                </ContactContainer >
                <img src={heroImageContactUs} alt={heroImageContactUs} />
            </>
        );
    };

    const isFormValid = () => {
        //return name && email && phone;

        let errs: TemplateParams = {};
        if (!name.trim()) {
            errs.name = "Name is required";
        }
        if (!email) {
            errs.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errs.email = "Email address is invalid";
        }
        //if (!phone) {
        //    errs.phone = "Phone number is required";
        //} else if (!/^\d{10}$/.test(phone)) {
        //    errs.phone = "Phone number is invalid";
        //}
        if (!message.trim()) {
            errs.message = "Message is required";
        }
        setErrors(errs);
        return Object.keys(errs).length === 0;
    };

    const sendEmail = (e: any) => {
        e.preventDefault(); // prevents the page from reloading when you hit �Send�

        if (!isFormValid()) {
            // Handle form validation error
            return;
        }

        const templateParams: Record<string, any> = {
            customer_name: name,
            customer_email: email,
            phone: phone,
            message: message,
            attachment,
        };

        emailjs.send(YOUR_SERVICE_ID, YOUR_TEMPLATE_ID, templateParams, YOUR_PUBLIC_KEY)
            .then((response: EmailJSResponseStatus) => {
                // show the user a success message
                alert("Successfully sent");
            }, (error: any) => {
                // show the user an error
                alert("Failure during send");
                console.log("Failure during send");
                console.dir(error);

            });
    };

    return (
        <Container>
            {buildPageHeader()}
            <FormContainer>
                <Form onSubmit={sendEmail} theme={theme[route]}>
                    <span></span>
                    <InputContainer>
                        <Label htmlFor="name">Name: <Required>*</Required></Label>
                        <Input type="text"
                            id="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)} />
                        {errors.name && <Error>{errors.name}</Error>}
                    </InputContainer>
                    <InputContainer>
                        <Label htmlFor="email">Email: <Required>*</Required></Label>
                        <Input type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                        {errors.email && <Error>{errors.email}</Error>}
                    </InputContainer>
                    <InputContainer>
                        <Label htmlFor="phone">Phone:</Label>
                        <Input type="tel"
                            id="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)} />
                        {errors.phone && <Error>{errors.phone}</Error>}
                    </InputContainer>
                    <InputContainer>
                        <Label htmlFor="message">Message: <Required>*</Required></Label>
                        <Textarea
                            id="message"
                            rows={5}
                            placeholder="Please be as detailed as possible regarding your ask for diamond jewelery specifications, quantity, purpose, budget, delivery location, and any additional requirements."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)} />
                        {errors.message && <Error>{errors.message}</Error>}
                    </InputContainer>
                    {/*<Label htmlFor="attachment">Attachment:</Label>*/}
                    {/*<FileInputLabel htmlFor="attachment">Choose File</FileInputLabel>*/}
                    {/*<FileInput type="file"*/}
                    {/*    id="attachment"*/}
                    {/*    onChange={(e) => {*/}
                    {/*        if (e.target.files) {*/}
                    {/*            setAttachment(e.target.files[0]);*/}
                    {/*        }*/}
                    {/*    }} />*/}

                    <SubmitButton type="submit">SEND</SubmitButton>
                </Form>
                <ImageContainer>
                    {buildMoreDetails()}
                </ImageContainer>
            </FormContainer>
        </Container>
    );
};

const theme: { [key: string]: { backgroundColor: string } } = {
    getquote: {
        backgroundColor: '#F3F1FF',
    },
    contactus: {
        backgroundColor: '#D0E1E6',
    },
};


const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  ${pageHeight}

    h2 {
     ${fontFamily(false)}
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    letter-spacing: 4px;
    font-weight: 300;
    color: #FFFFFF;
    padding-left: 2rem;
  }

  p{
    ${subFontFamily(false)}
   font-size: 1.2rem;
   letter-spacing: 0.5px;
   line-height:1.5rem;
   text-align: justify;
    padding: 1rem 2rem;
    color: #FFFFFF;
  }

    a{
    ${subFontFamily(false)}
   font-size: 1.2rem;
   letter-spacing: 0.5px;
   text-align: justify;
   }
    @media (max-width: 768px) {
        h2 {
        font-size: 1.2rem;
        padding: 1rem;
        margin-bottom: 0;
        }
        p {
        padding: 1rem;
        font-size: 1rem;
        }
        height: auto;
   }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
    justify-content: flex-start; // Align items at the start

  width: 100%;
  height:40vh;
  ${subFontFamily(false)}
     
     @media (max-width: 768px) {
         flex-direction: column; // Stack children vertically on smaller screens
         height:auto;
   }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  background-color: ${props => props.theme.backgroundColor};
  padding-left: 2rem;

   span{
    color: #202D30;
    padding: 1rem;
    padding-left: 0;
  }

    @media (max-width: 768px) {
       width: auto;
       padding-left: 1rem;
         padding-bottom: 1rem;
   }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
    width: 100%;
`;

const Label = styled.label`
  font-size: 18px;
  margin-bottom: 5px;
    width: 100%;
`;

const Input = styled.input`
  font-size: 16px;
  padding: 5px;
  border: none;
    margin-right: 1rem;
`;

const Textarea = styled.textarea`
  box-sizing: border-box;
  font-size: 16px;
  padding: 5px;
  border: none;
  margin-right: 1rem;

  ::placeholder {
   ${subFontFamily(true)}
}
`;

const FileInputLabel = styled.label`
  ${buttonStyle(false)}
  margin-top: 5px; //override above setting
`;

const FileInput = styled.input`
  display: none;
`;

const SubmitButton = styled.button`
  ${buttonStyle(true)}
   ${subFontFamily(false)}
   margin-left: auto;
    margin-right: 1rem;
    min-width:100px;
`;

const ImageContainer = styled.div`
    width:50%;
    display:flex;
    position: relative;

    img {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
       @media (max-width: 768px) {
        width:100%;
    }
`;

const Error = styled.span`
    color: red;
    font-size: 12px;
    margin-top: 5px;
`;

const Required = styled.span`
    color: #69601D;
    font-weight: bold;
`;

const Icon = styled.i`
  color: white;

    @media (max-width: 768px) {
                        color: #3F878C !important;
    }
`;

const ContactContainer = styled.div`
display: flex;
flex-direction: column;
position: absolute;
`;

const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left:2rem;
`;

const Row = styled.div`
    display: flex;
    align-items: center;   
    
    @media (max-width: 768px) {
        p{
                        color: #3F878C;
                        }
    }
`;

const DDLinkRow = styled.div`
    display: flex;
    flex-direction: column;
`;

const ContactInfo = styled.p`
            color: #3F878C !important;
            padding:0 !important;
            margin-top:-1rem !important;
         
            @media (max-width: 768px) {
                        color: white !important;

    }
`;

const DDLink = styled.a`
  color: #3F878C ;
  text-decoration: none;
  margin-right: 1rem;
    ${subFontFamily(false)}

     @media (max-width: 768px) {
       color: white;
    }
`;

export default GetQuotePage;